<template>
  <div class="chart-container mx-auto">
    <canvas id="chart" height="350" width="700"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)
const cpiColor = 'rgb(20, 93, 202)'
import cpi from '@/mixins/cpi'
export default {
  mixins: [cpi],
  data: () => ({
    chartData: {
      type: 'bar',
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            min: 90,
            display: true,
            title: {
              display: true,
              text: 'Index 1982-84 = 100',
              font: {
                size: '20px'
              }
            }
          }
        }
      },
      data: {
        labels: [],
        datasets: [
          {
            label: 'CPI',
            data: [],
            yAxisID: 'y',
            backgroundColor: cpiColor,
            maxBarThickness: 90,
            borderWidth: 1
          }
        ]
      }
    },
    context: null,
    chartObj: null,
    CHART_DATA: [
      // { year: 1980, cpi: 86.2 },
      // { year: 1981, cpi: 94.0 },
      { year: 1982, cpi: 97.7 },
      { year: 1983, cpi: 101.7 },
      { year: 1984, cpi: 106.0 },
      { year: 1985, cpi: 110.7 },
      { year: 1986, cpi: 112.5 },
      { year: 1987, cpi: 118.3 },
      { year: 1988, cpi: 124.5 },
      { year: 1989, cpi: 131.3 }
    ]
  }),
  mounted() {
    this.setContext()
    this.initChart()
    this.generateData()
  },
  computed: {
    cpiList() {
      return [...this.$store.getters.cpi].reverse()
    },
    chartCPIData() {
      let d = [...this.CHART_DATA, ...this.cpiList]
      d = d.sort((a, b) => a.year - b.year)
      return d
    }
  },
  methods: {
    setContext() {
      this.context = document.getElementById('chart').getContext('2d')
    },
    initChart() {
      this.chartObj = new Chart(this.context, this.chartData)
    },
    generateData() {
      this.chartData.data.datasets[0].data = []
      this.chartData.data.labels = []
      this.chartCPIData.forEach(data => {
        if (data.cpi) {
          this.chartData.data.labels.push(data.year)
          this.chartData.data.datasets[0].data.push(data.cpi)
        }
      })
      this.showChart()
    },
    showChart() {
      this.chartObj.update()
    }
  },
  watch: {
    chartCPIData() {
      this.generateData()
      this.showChart()
    }
  }
}
</script>

<style scoped>
.chart-container {
  max-width: 700px;
}
</style>
